import classNames from 'classnames';
import {forwardRef, PropsWithChildren, useEffect} from 'react';

import {DropdownContext} from '@/components/base/dropdown/DropdownContext';
import {useToggle} from '@/hooks/use-toggle';

import styles from './Overlay.module.css';

export interface OverlayProps {
	id: string;
	className?: string;
	onOpen?: (open: boolean) => void;
}

export const Overlay = forwardRef<HTMLDivElement, PropsWithChildren<OverlayProps>>(function Overlay(
	{id, className, children, onOpen},
	ref,
) {
	const [isOpen, setIsOpen, toggle] = useToggle(false);

	let isMoved = false;

	useEffect(() => {
		onOpen?.(isOpen);
	}, [isOpen, onOpen]);

	const contextValue = {
		open: isOpen,
		id,
		toggle,
	};

	const onMouseEnter = () => {
		setIsOpen(true);
	};

	const onMouseOver = () => {
		isMoved = true;
	};

	const onMouseLeave = () => {
		isMoved = false;

		setTimeout(() => {
			if (!isMoved) {
				setIsOpen(false);
			}
		}, 200);
	};

	return (
		<div
			ref={ref}
			className={classNames(styles.root, className)}
			onMouseOver={onMouseOver}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<DropdownContext.Provider value={contextValue}>{children}</DropdownContext.Provider>
		</div>
	);
});
